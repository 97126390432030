<template>
    <div>
        <el-row :gutter="5">
            <el-col :span="8">
                <el-divider>我的测点</el-divider>
                <vxe-button status="primary" @click="edit" icon="vxe-icon-edit">{{ $t("lang.编辑") }}</vxe-button>
                <vxe-table ref="xTable" :loading="tableLoading" :data="tableData" border :row-config="{ isHover: true, isCurrent: true }" @current-change="currentChange" show-overflow style="margin-top: 20px;" :cell-class-name="cellClassName">
                    <!-- <vxe-column field="sensorNumber" :title="$t('lang.传感器序列号')" width="150"></vxe-column> -->
                    <vxe-column field="sensorName" :title="$t('lang.测点名称')"></vxe-column>
                    <vxe-column field="meiTianJiangWen" :title="$t('lang.每天降温') + '(℃)'" align="right" width="110"></vxe-column>
                    <vxe-column field="meiSiXiaoShiJiangWen" :title="$t('lang.每四小时降温') + '(℃)'" align="right" width="130"></vxe-column>
                </vxe-table>
            </el-col>
            <el-col :span="16">
                <el-divider>{{ $t("lang.报警记录") }}</el-divider>
                <el-tabs value="0" stretch>
                    <el-tab-pane :label="$t('lang.每天降温报警')" name="0">
                        <vxe-table :loading="tableLoading1" :data="tableData1" border :cell-class-name="cellClassName" show-overflow>
                            <vxe-column field="wxcW_WXCWSensor.sensorName" :title="$t('lang.测点名称')"></vxe-column>
                            <vxe-column field="prev" :title="$t('lang.前一天')" width="100"></vxe-column>
                            <vxe-column field="prevTemperature" :title="$t('lang.平均温度') + '(℃)'" align="right" width="110"></vxe-column>
                            <vxe-column field="next" :title="$t('lang.后一天')" width="100"></vxe-column>
                            <vxe-column field="nextTemperature" :title="$t('lang.平均温度') + '(℃)'" align="right" width="110"></vxe-column>
                            <vxe-column field="createTime" :title="$t('lang.创建时间')" width="150"></vxe-column>
                        </vxe-table>
                        <vxe-pager align="left" @page-change="getList1" :page-size.sync="searchObj1.pageSize" :current-page.sync="searchObj1.currentPage" :total="searchObj1.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
                        </vxe-pager>
                    </el-tab-pane>
                    <el-tab-pane :label="$t('lang.每四小时降温报警')" name="1">
                        <vxe-table :loading="tableLoading2" :data="tableData2" border :cell-class-name="cellClassName" show-overflow>
                            <vxe-column field="wxcW_WXCWSensor.sensorName" :title="$t('lang.测点名称')"></vxe-column>
                            <vxe-column field="prev_4Hour" :title="$t('lang.时间间隔')" width="230" :formatter="({ cellValue }) => { return cellValue.replace(':00:00', '').replace(':00:00', '') }"></vxe-column>
                            <vxe-column field="avg_Temperature_Prev" :title="$t('lang.平均温度') + '(℃)'" align="right" width="110"></vxe-column>
                            <vxe-column field="next_4Hour" :title="$t('lang.时间间隔')" width="230" :formatter="({ cellValue }) => { return cellValue.replace(':00:00', '').replace(':00:00', '') }"></vxe-column>
                            <vxe-column field="avg_Temperature_Next" :title="$t('lang.平均温度') + '(℃)'" align="right" width="110"></vxe-column>
                            <vxe-column field="createTime" :title="$t('lang.创建时间')" width="150"></vxe-column>
                        </vxe-table>
                        <vxe-pager align="left" @page-change="getList2" :page-size.sync="searchObj2.pageSize" :current-page.sync="searchObj2.currentPage" :total="searchObj2.total" :layouts="['PrevPage', 'Jump', 'PageCount', 'NextPage', 'Total']">
                        </vxe-pager>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
        <!-- <vxe-input v-model="editRow.meiTianJiangWen"></vxe-input> -->
        <vxe-modal v-model="visibleEditModal" :title="editRow.sensorName" showFooter>
            <vxe-form :data="editRow" :loading="formLoading" title-width="100">
                <vxe-form-item field="meiTianJiangWen" :title="$t('lang.每天降温')">
                    <template #default="{ data }">
                        <vxe-input v-model="data.meiTianJiangWen"> </vxe-input>
                    </template>
                </vxe-form-item>
                <vxe-form-item field="meiSiXiaoShiJiangWen" :title="$t('lang.每四小时降温')">
                    <template #default="{ data }">
                        <vxe-input v-model="data.meiSiXiaoShiJiangWen"> </vxe-input>
                    </template>
                </vxe-form-item>
            </vxe-form>
            <template #footer>
                <vxe-button status="primary" @click="save" icon="vxe-icon-save">{{ $t("lang.保存") }}</vxe-button>
            </template>
        </vxe-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableLoading: false,
            tableData: [],
            tableLoading1: false,
            searchObj1: {
                sensorId: 0,
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            tableData1: [],
            tableLoading2: false,
            searchObj2: {
                sensorId: 0,
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            tableData2: [],
            editRow: {},
            visibleEditModal: false,
            formLoading: false,

        };
    },
    methods: {
        edit() {
            let that = this;
            let cr = that.$refs.xTable.getCurrentRecord();
            if (cr) {
                that.visibleEditModal = true;
                that.formLoading = true;
                that.axios.post("WXCW_WXCWSensor/GetModel", { id: cr.id }).then((response) => {
                    that.editRow = response.data.data;
                    that.formLoading = false;
                });
            }
            else {
                that.$vxe.modal.message({ content: that.$t("lang.请选择测点"), status: "question" });
            }
        },
        save() {
            let that = this;
            that.axios.post("WXCW_WXCWSensor/EditModel", that.editRow).then((response) => {
                if (response.data.code == 101) {
                    that.$vxe.modal.message({ content: that.$t("lang.保存成功"), status: "success" });
                    that.visibleEditModal = false;
                    that.getList();
                }
            });
        },
        currentChange({ newValue }) {
            let that = this;
            that.searchObj1.sensorId = newValue.id;
            that.searchObj2.sensorId = newValue.id;
            that.getList1();
            that.getList2();
        },
        getList1() {
            let that = this;
            that.tableLoading1 = true;
            that.axios.post("WXCW81/GetMeiTianBaoJing", that.searchObj1).then((response) => {
                that.tableData1 = response.data.data.data;
                that.searchObj1.total = response.data.data.total;
                that.tableLoading1 = false;
            });
        },
        getList2() {
            let that = this;
            that.tableLoading2 = true;
            that.axios.post("WXCW81/GetMeiSiXiaoShiBaoJing", that.searchObj2).then((response) => {
                that.tableData2 = response.data.data.data;
                that.searchObj2.total = response.data.data.total;
                that.tableLoading2 = false;
            });
        },
        cellClassName({ column }) {
            if (column.field == "prevTemperature" || column.field == "nextTemperature" || column.field == "avg_Temperature_Prev" || column.field == "avg_Temperature_Next") {
                return "sumj-all-font-red-bold";
            }
            else if (column.field == "meiTianJiangWen" || column.field == "meiSiXiaoShiJiangWen") {
                return "sumj-all-font-success-bold";
            }
            else {
                return "";
            }
        },
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios.post("WXCW81/GetUserSensorList", {}).then((response) => {
                that.tableLoading = false;
                that.tableData = response.data.data;
                if (that.tableData.length > 0) {
                    that.$refs.xTable.setCurrentRow(that.tableData[0]);
                    that.currentChange({ newValue: that.tableData[0] });
                }
            });
        }
    },
    mounted() {
        let that = this;
        that.getList();
    }
};
</script>

<style>
.sumj-all-font-red-bold {
    color: #F56C6C;
    font-weight: bolder;
}

.sumj-all-font-success-bold {
    color: #F56C6C;
    font-weight: bolder;
}
</style>